.quill .ql-toolbar {
  border: 0 !important;
}
.quill .ql-container {
  border: 0 !important;
  border-top: 1px solid #d1d5db !important;
}

.ql-editor {
  min-height: 200px;
}
.phone_container {
  font-family: 'Montserrat', sans-serif !important;
}
.phone_input {
  height: auto !important;
  border-radius: 9999px !important;
  padding: 0.75rem !important;
  padding-left: 3.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  border-color: rgb(209 213 219) !important;
  width: 100% !important;
  display: block !important;
  margin-top: 0.25rem !important;
}
.react-tel-input .selected-flag {
  padding: 0 0 0 16px !important;
  width: 48px !important;
}
.react-tel-input .selected-flag:focus,
.react-tel-input .selected-flag:hover {
  background-color: transparent !important;
}
.phone_flag_dropdown {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
  background-color: #fff !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent !important;
}
.hoverable--overlay {
  display: none !important;
}
.hoverable:hover .hoverable--overlay {
  display: flex !important;
}

.video {
  transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1)
    scaleY(1.1);
}
.hire__control {
  border-radius: 9999px !important;
  width: 100%;
  padding: 0.25rem;
}
.filter-select .hire__control{
  background-color: #f0fdf4 !important;
  padding: 0.4rem 0 0.4rem 1rem !important;
}
.hire__control:hover{
  border-color:#22c55e !important;
}
.hire__control--is-focused{
  border-color:#22c55e !important;
  box-shadow: 0 0 0 1px #22c55e !important;
}
.hire__input:focus {
  box-shadow: none !important;
}
.hire__single-value,
.hire__menu-list {
  text-transform: capitalize;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.hire__multi-value{
  border-radius: 200px !important;
  background-color: #16a34a !important;
  color: #fff !important;
}
.hire__multi-value__label{
  color: #fff !important;
  padding-left: 8px !important;
}
.hire__multi-value__remove:hover{
  background-color: #15803d !important;
  color: #fff !important;
  border-radius: 200px !important;
}

@media (min-width: 1024px){
  .filter-select .hire__control{
    padding: 0 !important;
  }
  .orgaization-user .hire__control {
    padding: 0.1rem !important;
  }
}
.hire-menu {
  overflow: hidden;
}
.callback-height {
  height: calc(100vh - 65px);
}
.orgaization-user .hire__control {
  padding: 0 !important;
}
.orgaization-user .hire__placeholder {
  font-size: 0.875rem;
}
.hire-job-description ul{
  margin-left: 2rem;
}
.hire-job-description ul li{
  list-style-type: circle;
}
@media (max-width: 760px){
  .orgaization-user .hire__control {
    padding: 0.14rem !important;
  }
}

/* tag css */
.tags-input:focus {
  outline: transparent;
}


#tags {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
}

.tag {
	width: auto;
	height: 32px;
	padding: 0 6px;
	list-style: none;
	margin: 0 8px 8px 0;
}
.tag .tag-title {
  margin-top: 1px;
}
.tag .tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #166534;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}