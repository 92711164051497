@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  * {
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
    box-sizing: border-box;
    transition: 0.03s ease-in;
  }
  body {
    margin: 0;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: 'Montserrat', sans-serif;
  }
  .selectTag::after {
    content: '\2715';
    margin-left: 0.25rem;
  }
  .country__control {
    border-width: 0 !important;
  }
  .hire-carousel .slide {
    @apply p-1 md:p-0 !important;
  }
}
